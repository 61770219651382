import React from "react"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "theme-default",
        }}
      />
      <main className="mx-8 my-8">{children}</main>
    </>
  )
}

export default Layout
